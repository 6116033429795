import '../css/dashboard.css';
import '../css/orders.css';
import '../css/order.css';
import useFetch from "../api/useFetch";
import { useState } from 'react';
import Updated from '../static/img/updated.png';
import Loading from './Loading';
import OrderLine from './OrderLine'
import ShipmentForm from './ShipmentForm';
import OrderUpdateForm from './OrderUpdateForm';
import CommentForm from './CommentForm';


const Orders = ({status_filter, notify, search_orders=null}) => {
    const [refresh, setRefresh] = useState(false);
    const [shipmentFormVisible, setShipmentFormVisible] = useState(false)
    const [commentFormVisible, setCommentFormVisible] = useState(false)
    const [page, setPage] = useState(1)
    const [orderUpdateFormVisible, setOrderUpdateFormVisible] = useState(false)
    const { result: fetchedOrders, isLoading: fetchedOrdersLoading, error: fetchedOrdersError } = useFetch('/get_martelle_orders?page=' + page + '&status_filter='+status_filter,[refresh]);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    
    console.log('search results in Orders.js: ' , search_orders)
    const orders = search_orders ? { items: search_orders, total: search_orders.length } : fetchedOrders
    const ordersLoading = search_orders ? false : fetchedOrdersLoading
    const ordersError = search_orders ? null : fetchedOrdersError


  const handleRefresh = () => {
      setRefresh(!refresh)
  }

  const openShipmentForm = () => {
    setShipmentFormVisible(true);
  };

  const openOrderUpdateForm = () => {
    setOrderUpdateFormVisible(true);
  };
    

  const openCommentForm = () => {
    setCommentFormVisible(true);

  }

  const handleUpdateClick = (orderId) => {
    setSelectedOrderId(orderId);
  };

  const handlePreviousClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < orders.total / 15){
      setPage(page + 1);}
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = (pages) => {
    const totalPages = pages;
    const pageNumbers = [];
    const ellipsis = <div>...</div>;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
        pageNumbers.push(
          <div
            key={i}
            className={`number${page === i ? " active" : ""}`}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </div>
        );
      } else if (pageNumbers[pageNumbers.length - 1] !== ellipsis) {
        pageNumbers.push(ellipsis);
      }
    }

    return pageNumbers;
  };
   
  return (
    <>
      <div div className="orders">
        <div div className="title"> COMMANDES {status_filter === 'a_envoyer' && 'A ENVOYER'}{status_filter === 'a_saisir' && 'A SAISIR'}{status_filter === 'late' && 'EN RETARD'}{status_filter === 'en_commande' && 'EN ATTENTE'}{status_filter === 'incomplete' && 'INCOMPLETES'}<i className="fas fa-sync" onClick={handleRefresh}></i></div>
        {!ordersLoading &&  orders && orders.total > 0 && status_filter === 'a_envoyer' && <button id="shipment-form-button"  onClick={openShipmentForm} ><i className="fas fa-plane-departure"></i>{`Créer Envoi [Poids Théorique Actuel: ${orders.items.filter(item => item.status === status_filter).reduce((sum,element) => sum + parseFloat(element['quantity']) * parseFloat(element['product']['weight']), 0).toFixed(1) } kg]`}</button>}
          <div className="table">
            <div className="table-header">
              <div className="date">Date</div>
              <div className="small">Commande</div>
              <div className="product">Nom du Livre</div>
              <div className="order_id">EAN</div>
              <div className="small">Quantité</div>
              <div className="medium">Fournisseur</div>
              <div className="price">Prix TTC</div>
              <div className="medium">Date Envoi Max</div>
              <div className="status">Statut</div>
              <div className="medium">{status_filter === 'a_envoyer' && 'Envoyer'}</div>
            </div>
            <div className="table-body">
              {ordersLoading && <div div className='table-placeholder'><Loading /></div>}
              {ordersError && <div>{ordersError}</div>}
              {!ordersLoading &&  orders && orders.total > 0 && orders.items.map((order) => <OrderLine  id={order.id}  global_order_id = {order.order.id} latest_date={order.latest_shipping_date} is_multi={order.is_multi} order_date={order.creation_date} product_name={order.product.name} ean={order.product.ean} quantity={order.quantity}  status={order.status} supplier={order.suppliers}  price={order.product.ttc_price_dilicom} is_external={order.is_external} notify={notify} refresh={refresh} setRefresh={setRefresh} openOrderUpdateForm={openOrderUpdateForm} openCommentForm={openCommentForm} onUpdateClick={handleUpdateClick} comment={order.comment}/>)}
              {!ordersLoading && orders && orders.total === 0 && <img src={Updated} alt="" width="200px" id='updated'/>}
            </div>
          </div>
        </div>
        {!ordersLoading && orders && orders.total > 15 && (
        <div div className="pagination">
          <div div className="number" onClick={handlePreviousClick}>
            <i className="fas fa-angle-left"></i>
          </div>
          {renderPageNumbers(parseInt(orders.total / 15) + 1)}
          <div div className="number" onClick={handleNextClick}>
            <i className="fas fa-angle-right"></i>
          </div>
        </div>
      )}
        {shipmentFormVisible && (<><ShipmentForm notify = {notify}  visible={shipmentFormVisible} setVisible={setShipmentFormVisible}  setRefresh={setRefresh} refresh={refresh} isMulti={false}/><div className="bg-disabled"></div></>)}
        {orderUpdateFormVisible && (<><OrderUpdateForm notify = {notify}  visible={orderUpdateFormVisible} setVisible={setOrderUpdateFormVisible}  setRefresh={setRefresh} refresh={refresh} isMulti={false} orderId={selectedOrderId}/><div className="bg-disabled"></div></>)}
        {commentFormVisible && (<><CommentForm notify = {notify}  visible={commentFormVisible} setVisible={setCommentFormVisible}  setRefresh={setRefresh} refresh={refresh} isMulti={false} orders={orders.items} orderId={selectedOrderId}/></>)}
    </>
  );  
}

export default Orders